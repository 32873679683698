import React from "react";
import Select from "react-select";
import "./style.css";
import { useTranslation } from "react-i18next";

function CustomSelect({ field, options, error, placeholder, onChange }) {
  const { t } = useTranslation();

  return (
    <>
      <Select
        className="react-select-container"
        styles={{
          option: (base) => ({
            ...base,
            color: `#0f121f`,
          }),
        }}
        options={options}
        placeholder={placeholder ?? t("form.select")}
        value={options.find((x) => x.value === field.value) ?? field.value}
        onChange={(option) => {
          field.onChange(option ? option.value : option);
          onChange && onChange(option ? option.value : option);
        }}
        aria-invalid={error ? "true" : "false"}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span uk-icon="warning"></span>
          <span className="uk-margin-small-right uk-margin-small-left">
            {error.message}
          </span>
        </small>
      )}
    </>
  );
}

export default CustomSelect;
