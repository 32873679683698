import React from "react";
import i18next from "i18next";
import { NavLink } from "react-router-dom";

function CustomButton({
  text,
  className,
  bg,
  withArrow = true,
  to,
  state,
  onClick,
}) {
  const isEnglish = i18next.language === "en";

  return to ? (
    <NavLink
      to={to}
      state={state}
      className={`uk-button${
        bg === "secondary"
          ? " uk-button-secondary"
          : bg === "black"
          ? " uk-button-black"
          : bg === "red"
          ? " uk-button-danger"
          : " uk-button-primary"
      } uk-border-pill ${className ? className : ""}`}
    >
      <span className="uk-text-truncate" style={{ maxWidth: 120 }}>
        {text}
      </span>

      {withArrow && (
        <span
          className="uk-margin-small-right"
          style={{ width: 20 }}
          uk-icon={isEnglish ? "chevron-right" : "chevron-left"}
        ></span>
      )}
    </NavLink>
  ) : (
    <button
      onClick={onClick}
      className={`uk-button${
        bg === "secondary"
          ? " uk-button-secondary"
          : bg === "black"
          ? " uk-button-black"
          : bg === "red"
          ? " uk-button-danger"
          : " uk-button-primary"
      }  uk-border-pill ${className ? className : ""}`}
    >
      <span className="uk-text-nowrap" style={{ maxWidth: 120 }}>
        {text}
      </span>

      {withArrow && (
        <span
          className="uk-margin-small-right"
          style={{ width: 20 }}
          uk-icon={isEnglish ? "chevron-right" : "chevron-left"}
        ></span>
      )}
    </button>
  );
}

export default CustomButton;
