import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import IntroSection from "../../components/IntroSection";
import useApiResolver from "../../hooks/useApiResolver";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import { NavLink } from "react-router-dom";

function Brands() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: brands } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/Brands/WebGetAll" });
  }, []);

  return (
    <>
      <IntroSection
        headerPartOne={t("brands.header.headerPartOne")}
        headerPartTwo={t("brands.header.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="uk-container uk-container-small uk-padding">
          <div className="uk-child-width-1-3@s uk-grid-medium" uk-grid="">
            {brands.map((brand) => (
              <NavLink
                to={`/Brand/${brand.id}`}
                key={brand.id}
                className="uk-link-reset"
              >
                <div className="uk-border-rounded bg-gradient uk-border-rounded uk-text-center uk-padding">
                  <h1 className="uk-text-white uk-text-bold">
                    {isEnglish ? brand.nameEn : brand.nameAr}
                  </h1>
                </div>
              </NavLink>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

export default Brands;
