import React from "react";
import { useTranslation } from "react-i18next";
import CustomHeader from "../../components/CustomHeader";
import CustomButton from "../../components/CustomButton";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import useApiResolver from "../../hooks/useApiResolver";
import IntroSection from "../../components/IntroSection";

function Contact() {
  const { t } = useTranslation();
  const { resolveApi, isLoading } = useApiResolver();

  const formValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  };

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: formValues,
  });

  // Handle send message
  const handleSendMessage = async (data) => {
    resolveApi({
      path: "/customerContacts/AddOne",
      method: "post",
      body: data,
      successMsg: t("common.messageSent"),
      errMeg: t("common.requestFailed"),
    }).then(() => reset(formValues));
  };

  return (
    <>
      {isLoading && <LoadingOverlay fullScreen transparent />}

      <IntroSection
        headerPartOne={t("contact.header.headerPartOne")}
        headerPartTwo={t("contact.header.headerPartTwo")}
      />

      <section className="contact uk-container uk-container-small uk-padding-large">
        <div
          className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@l uk-grid-small"
          uk-grid=""
        >
          <div>
            <div className="contact-method uk-border-rounded">
              <i className="icon fi fi-ts-land-layer-location"></i>
              <h3>{t("contact.address")}</h3>
              <p>المملكة العربية السعودية</p>
            </div>
          </div>
          <div>
            <div className="contact-method uk-border-rounded">
              <i className="icon fi fi-ts-circle-envelope"></i>
              <h3>{t("contact.email")}</h3>
              <p>mail@aldahyan.com</p>
            </div>
          </div>
          <div>
            <div className="contact-method uk-border-rounded">
              <i className="icon fi fi-tr-phone-call"></i>
              <h3>{t("contact.mobile")}</h3>
              <p>+9911212212</p>
            </div>
          </div>
        </div>
        <div className="uk-margin-large-top uk-margin-bottom">
          <CustomHeader
            label={t("common.headerLabel")}
            headerPartOne={t("contact.subHeader.headerPartOne")}
            headerPartTwo={t("contact.subHeader.headerPartTwo")}
            centerText
          />
        </div>

        <form onSubmit={handleSubmit(handleSendMessage)}>
          <div className="uk-child-width-1-2@s uk-grid-small" uk-grid="">
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-text-bold uk-text-primary">
                {t("form.name")}
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="name"
                  error={errors.name}
                />
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-text-bold uk-text-primary">
                {t("form.mobile")}
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="phone"
                  error={errors.phone}
                />
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-text-bold uk-text-primary">
                {t("form.email")}
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="email"
                  error={errors.email}
                  type="email"
                />
              </div>
            </div>
            <div className="uk-margin-bottom">
              <label className="uk-form-label uk-text-bold uk-text-primary">
                {t("form.address")}
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="address"
                  error={errors.address}
                />
              </div>
            </div>
            <div className="uk-margin-bottom uk-width-1-1">
              <label className="uk-form-label uk-text-bold uk-text-primary">
                {t("form.message")}
              </label>
              <div className="uk-form-controls uk-margin-small-top">
                <CustomInput
                  control={control}
                  name="message"
                  error={errors.message}
                  textarea
                />
              </div>
            </div>
          </div>
          <div className="uk-text-center uk-margin-top">
            <CustomButton text={t("form.send")} withArrow />
          </div>
        </form>
      </section>
    </>
  );
}

export default Contact;
