import React, { useEffect } from "react";
import CustomHeader from "../../components/CustomHeader";
import { useTranslation } from "react-i18next";
import useApiResolver from "../../hooks/useApiResolver";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import { NavLink } from "react-router-dom";
import { base_url } from "../../helpers/Constants";

function NewsSection() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: news } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/News/GetAll" });
  }, []);

  return (
    <section className="uk-container uk-container-small uk-padding">
      <CustomHeader
        label={t("common.headerLabel")}
        headerPartOne={t("home.news.header.headerPartOne")}
        headerPartTwo={t("home.news.header.headerPartTwo")}
        centerText
      />
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div uk-slider="" className="news-slider uk-margin-small-top">
          <div className="uk-position-relative">
            <div className="uk-slider-container uk-light">
              <div className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-small uk-grid-match">
                {news.map((item) => (
                  <div key={item.id} className="news-card">
                    <div
                      className="cover uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover"
                      data-src={`${base_url}/${item.image}`}
                      uk-img=""
                    ></div>
                    <div className="details">
                      <strong>{isEnglish ? item.nameEn : item.nameAr}</strong>
                      <NavLink to={`/News/${item.id}`} className="more">
                        {t("home.news.viewMore")}
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="uk-hidden@m uk-light">
              <button
                className="uk-position-center-left uk-position-small"
                uk-slidenav-previous=""
                uk-slider-item="previous"
              ></button>
              <button
                className="uk-position-center-right uk-position-small"
                uk-slidenav-next=""
                uk-slider-item="next"
              ></button>
            </div>

            <div className="uk-visible@m">
              <button
                className="uk-position-center-left-out uk-position-small"
                uk-slidenav-previous=""
                uk-slider-item="previous"
              ></button>
              <button
                className="uk-position-center-right-out uk-position-small"
                uk-slidenav-next=""
                uk-slider-item="next"
              ></button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default NewsSection;
