import React, { useEffect } from "react";
import CustomHeader from "../../components/CustomHeader";
import { useTranslation } from "react-i18next";
import { base_url } from "../../helpers/Constants";
import { NavLink } from "react-router-dom";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import useApiResolver from "../../hooks/useApiResolver";

function OptionsSection() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: cars } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/Cars/WebGetAll" });
  }, []);

  return (
    <section className="uk-container uk-container-small uk-padding">
      <CustomHeader
        label={t("common.headerLabel")}
        headerPartOne={t("home.options.header.headerPartOne")}
        headerPartTwo={t("home.options.header.headerPartTwo")}
        centerText
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div
          uk-slider=""
          className="options-slider uk-margin-small-top uk-margin-bottom"
        >
          <div className="uk-position-relative">
            <div className="uk-slider-container uk-light">
              <div className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-small">
                {cars?.map((car) => (
                  <NavLink to={`/Car/${car.id}`} key={car.id}>
                    <div
                      className="car-card uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-border-rounded uk-position-relative uk-margin-large-bottom"
                      data-src={`${base_url}/${car.imageProfile}`}
                      uk-img=""
                    >
                      <div className="actions uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
                        <div>
                          <div className="uk-button uk-padding-remove bg-transparent uk-color-white">
                            <i className="fi fi-tr-audit icon-2x"></i>
                            <p className="uk-margin-remove">
                              {t("home.options.viewDetails")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="info uk-border-rounded">
                        <h3>{isEnglish ? car.nameEn : car.nameAr}</h3>
                        <p>{car.manufacture_year}</p>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="uk-hidden@m uk-light">
              <button
                className="uk-position-center-left uk-position-small"
                uk-slidenav-previous=""
                uk-slider-item="previous"
              ></button>
              <button
                className="uk-position-center-right uk-position-small"
                uk-slidenav-next=""
                uk-slider-item="next"
              ></button>
            </div>

            <div className="uk-visible@m">
              <button
                className="uk-position-center-left-out uk-position-small"
                uk-slidenav-previous=""
                uk-slider-item="previous"
              ></button>
              <button
                className="uk-position-center-right-out uk-position-small"
                uk-slidenav-next=""
                uk-slider-item="next"
              ></button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default OptionsSection;
