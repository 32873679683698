import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import { base_url } from "../../helpers/Constants";
import { getFormattedDate } from "../../helpers/DateHelper";
import CustomButton from "../../components/CustomButton";
import useApiResolver from "../../hooks/useApiResolver";
import IntroSection from "../../components/IntroSection";

function MaintenanceOffers() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: offers } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/maintenance_offers/GetAll" });
  }, []);

  return (
    <>
      <IntroSection
        headerPartOne={t("offers.maintenanceHeader.headerPartOne")}
        headerPartTwo={t("offers.maintenanceHeader.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="uk-container uk-container-small uk-padding-large">
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small"
            uk-grid=""
          >
            {offers?.map((offer) => (
              <div key={offer.id}>
                <div className="offer-card uk-box-shadow-small uk-border-rounded">
                  <div
                    className="uk-height-small uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-position-relative"
                    data-src={`${base_url}/${offer.imageFile}`}
                    uk-img=""
                  >
                    <div className="discount">
                      {t("offers.discount")} {offer.offer_discount}%
                    </div>
                  </div>
                  <div className="info">
                    <p className="offer-end">
                      {t("offers.offerEnd")}
                      <span className="uk-padding-small">
                        {getFormattedDate(offer.offer_end)}
                      </span>
                    </p>
                    <h3>{isEnglish ? offer.nameEn : offer.nameAr}</h3>
                    <div>
                      {isEnglish ? offer.descriptionEn : offer.descriptionAr}
                    </div>
                    <div className="price">
                      <p className="uk-margin-remove">
                        <s className="uk-text-small uk-text-meta">
                          {offer.price_before} {t("common.currency")}
                        </s>
                      </p>
                      <span className="uk-padding-small uk-text-primary">
                        {offer.price_after} {t("common.currency")}
                      </span>
                    </div>

                    <div className="actions">
                      <CustomButton
                        text={t("offers.order")}
                        to="/Maintenance"
                        state={{ isMaintenanceOnly: true }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

export default MaintenanceOffers;
