import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import { base_url } from "../../helpers/Constants";
import useApiResolver from "../../hooks/useApiResolver";
import IntroSection from "../../components/IntroSection";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactSelect from "react-select";

function Brand() {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: brandInfo = {} } = useApiResolver();
  const {
    resolveApi: getBrandTypes,
    data: brandTypes,
    isLoading: brandTypesIsLoading,
  } = useApiResolver();
  const [selectedBrandType, setSelectedBrandType] = useState(0);

  // Brand Types Options
  const brandTypesOptions = brandTypes.map((brandType) => ({
    value: brandType.id,
    label: isEnglish ? brandType.nameEn : brandType.nameAr,
  }));
  brandTypesOptions.unshift({
    value: 0,
    label: t("form.all"),
  });

  // Cars list for view on scroll
  const [cars, setCars] = useState([]);
  useEffect(() => {
    setCars(brandInfo?.cars?.slice(0, 18) ?? []);
  }, [brandInfo]);

  const getCars = () => {
    setTimeout(() => {
      setCars(brandInfo?.cars.slice(0, cars?.length + 18));
    }, 1000);
  };

  // Get Brand Types
  useEffect(() => {
    setSelectedBrandType(0);
    getBrandTypes({
      path: `/brand_types/WebGetAllByBrands/${id}`,
    });
  }, [id]);

  useEffect(() => {
    resolveApi({
      path: `/Cars/GetAllByBrands/${id}`,
      method: "post",
      body: {
        brandType_id: selectedBrandType,
      },
    });
  }, [id, selectedBrandType]);

  return (
    <>
      <IntroSection
        headerPartOne={t("brand.header.headerPartOne")}
        headerPartTwo={isEnglish ? brandInfo.nameEn : brandInfo.nameAr}
      />
      <section className="uk-container uk-container-small uk-padding-large">
        <div className="uk-form-controls uk-margin-medium-bottom uk-flex-middle uk-flex-inline">
          <label className="uk-form-label uk-text-bold uk-text-white">
            {t("form.brandType")}
          </label>
          <ReactSelect
            className="react-select-container uk-width-medium uk-margin-small-right uk-margin-small-left"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral80: "#ffffff",
                primary25: "#c19853",
                primary: "#c19853",
                primary50: "#c19853",
                neutral0: "#0f121f",
              },
            })}
            styles={{
              container: (base) => ({
                ...base,
                zIndex: 11111,
              }),
            }}
            value={brandTypesOptions.find((x) => x.value === selectedBrandType)}
            options={brandTypesOptions}
            placeholder={t("form.select")}
            onChange={(option) => setSelectedBrandType(option.value)}
          />
        </div>
        <InfiniteScroll
          dataLength={cars?.length}
          hasMore={cars?.length < brandInfo?.count}
          next={getCars}
          loader={<LoadingOverlay />}
        >
          {isLoading || brandTypesIsLoading ? (
            <LoadingOverlay />
          ) : (
            <div
              className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small"
              uk-grid=""
            >
              {cars?.map((car) => (
                <NavLink to={`/Car/${car.id}`} key={car.id}>
                  <div
                    className="car-card uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-border-rounded uk-position-relative uk-margin-large-bottom"
                    data-src={`${base_url}/${car.imageProfile}`}
                    uk-img=""
                  >
                    <div className="actions uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
                      <div>
                        <div className="uk-button uk-padding-remove bg-transparent uk-color-white">
                          <i className="fi fi-tr-audit icon-2x"></i>
                          <p className="uk-margin-remove">
                            {t("home.options.viewDetails")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="info uk-border-rounded">
                      <h3>{isEnglish ? car.nameEn : car.nameAr}</h3>
                      <p>{car.manufacture_year}</p>
                    </div>
                  </div>
                </NavLink>
              ))}
            </div>
          )}
        </InfiniteScroll>
      </section>
    </>
  );
}

export default Brand;
