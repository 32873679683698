import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import CarOffers from "../pages/Offers/CarOffers";
import News from "../pages/News";
import Brand from "../pages/Brand";
import Advices from "../pages/Advices";
import Branches from "../pages/Branches";
import WarningSigns from "../pages/WarningSigns";
import Contact from "../pages/Contact";
import Car from "../pages/Car";
import MaintenanceOffers from "../pages/Offers/MaintenanceOffers";
import NewsDetails from "../pages/News/NewsDetails";
import About from "../pages/About";
import Brands from "../pages/Brands";
import FinanceCalc from "../pages/FinanceCalc";
import MaintenanceRequest from "../pages/MaintenanceRequest";
import SathaRequest from "../pages/SathaRequest";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/CarOffers" element={<CarOffers />} />
      <Route path="/News" element={<News />} />
      <Route path="/News/:id" element={<NewsDetails />} />
      <Route path="/Advices" element={<Advices />} />
      <Route path="/Brands" element={<Brands />} />
      <Route path="/Brand/:id" element={<Brand />} />
      <Route path="/Branches" element={<Branches />} />
      <Route path="/WarningSigns" element={<WarningSigns />} />
      <Route path="/MaintenanceRequest" element={<MaintenanceRequest />} />
      <Route path="/SathaRequest" element={<SathaRequest />} />
      <Route path="/MaintenanceOffers" element={<MaintenanceOffers />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Car/:id" element={<Car />} />
      <Route path="/FinanceCalc" element={<FinanceCalc />} />
    </Routes>
  );
}

export default AppRoutes;
