import translationAR from "./ar/ar.json";
import translationEN from "./en/en.json";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const langFromLocalStorage =
  localStorage.getItem("lang") === "en" ? "en" : "ar";

const resources = {
  ar: {
    translation: translationAR,
  },
  en: {
    translation: translationEN,
  },
};

i18next.use(initReactI18next).init({
  fallbackLng: langFromLocalStorage || "ar",
  debug: false,
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
