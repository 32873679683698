import React from "react";
import logo from "../../assets/imgs/logo.png";
import img from "../../assets/imgs/footer_pic.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="uk-padding-large uk-padding-remove-horizontal@m bg-black">
      <div className="uk-grid-large" uk-grid="">
        <div className="uk-width-1-3 uk-visible@m">
          <div>
            <img src={img} className="car-img uk-object-contain" />
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-3@m">
          <NavLink to="/" className="">
            <img src={logo} alt="Aldahyan Logo" />
          </NavLink>

          <p>{t("footer.about")}</p>

          <div>
            <a href="" uk-icon="facebook"></a>
            <a href="" uk-icon="x" className="uk-margin-small-right"></a>
            <a
              href=""
              uk-icon="instagram"
              className="uk-margin-small-right"
            ></a>
          </div>
        </div>
        <div className="uk-width-1-2@s uk-width-1-3@m">
          <h4 className="uk-text-white">{t("footer.links.title")}</h4>

          <ul className="uk-list">
            <li>
              <NavLink to="/About" className="uk-link-reset uk-text-small">
                {t("footer.links.about")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Brands" className="uk-link-reset uk-text-small">
                {t("footer.links.cars")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Branches" className="uk-link-reset uk-text-small">
                {t("footer.links.branches")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/News" className="uk-link-reset uk-text-small">
                {t("footer.links.news")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/MaintenanceRequest"
                className="uk-link-reset uk-text-small"
              >
                {t("footer.links.maintenance")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Contact" className="uk-link-reset uk-text-small">
                {t("footer.links.contact")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
