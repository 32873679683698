import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import useApiResolver from "../../hooks/useApiResolver";
import IntroSection from "../../components/IntroSection";

function Advices() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: advices } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/car_advices/GetAll" });
  }, []);

  return (
    <>
      <IntroSection
        headerPartOne={t("advice.header.headerPartOne")}
        headerPartTwo={t("advice.header.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="uk-container uk-container-small uk-padding-large">
          <ul uk-accordion="">
            {advices?.map((advice, index) => (
              <li key={advice.id} className={index == 0 ? "uk-open" : ""}>
                <a className="uk-accordion-title" href="">
                  {isEnglish ? advice.nameEn : advice.nameAr}
                </a>
                <div className="uk-accordion-content">
                  <p>
                    {isEnglish ? advice.descriptionEn : advice.descriptionAr}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </section>
      )}
    </>
  );
}

export default Advices;
