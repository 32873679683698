import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import useApiResolver from "../../hooks/useApiResolver";
import { base_url } from "../../helpers/Constants";
import { useParams } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import IntroSection from "../../components/IntroSection";

function NewsDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: newsDetails } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: `/News/${id}` });
  }, [id]);

  return (
    <>
      <IntroSection
        headerPartOne={t("news.header.headerPartOne")}
        headerPartTwo={t("news.header.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="uk-container uk-container-small uk-padding-large">
          <div
            className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-border-rounded"
            data-src={`${base_url}/${newsDetails?.image}`}
            uk-img=""
          ></div>
          <h4 className="uk-text-white uk-text-bold">
            {isEnglish ? newsDetails?.nameEn : newsDetails?.nameAr}
          </h4>
          <p className="uk-text-small">
            {isEnglish
              ? newsDetails?.descriptionEn
              : newsDetails?.descriptionAr}
          </p>

          <div className="uk-text-center uk-margin-medium-top">
            <CustomButton text={t("news.back")} to="/News" />
          </div>
        </section>
      )}
    </>
  );
}

export default NewsDetails;
