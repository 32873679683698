import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import useApiResolver from "../../hooks/useApiResolver";
import CustomButton from "../../components/CustomButton";
import { paymentPeriodOptions } from "../../helpers/Constants";
import ScrollTop from "../../helpers/ScrollTop";
import FinanceCalcResult from "./FinanceCalcResult";

function FinanceCalcForm({ bankOptions, car_id, price, setIsLoading }) {
  const { t } = useTranslation();

  const formValues = {
    car_id,
    price,
    customer_name: "",
    email: "",
    mobile: "",
    payment_period: "",
    month_payment: "",
    paid_money: "",
    salary: "",
    commitments: "",
    lastpayment: "",
    bank_id: "",
    remarks: "",
  };

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: formValues,
  });

  const {
    resolveApi,
    isLoading,
    data: resultData,
    isSuccess,
  } = useApiResolver();
  const [financeCalcResult, setFinanceCalcResult] = useState(null);

  // Handle show loader
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  // Handle reset request form
  useEffect(() => {
    isSuccess && reset(formValues);
  }, [isSuccess]);

  // Set calc response data
  useEffect(() => {
    resultData.month_payment && setFinanceCalcResult(resultData);
  }, [resultData]);

  // Handle send request
  const handleFinanceCalc = async (data) => {
    await resolveApi({
      path: "/car_finance_orders/AddOne",
      method: "post",
      body: data,
      errMeg: t("common.requestFailed"),
    });
  };

  return (
    <>
      <ScrollTop key={financeCalcResult} />

      {financeCalcResult ? (
        <FinanceCalcResult
          financeCalcResult={financeCalcResult}
          setFinanceCalcResult={setFinanceCalcResult}
          setIsLoading={setIsLoading}
        />
      ) : (
        <form
          onSubmit={handleSubmit(handleFinanceCalc)}
          className="uk-child-width-1-2@s uk-grid-small"
          uk-grid=""
        >
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.name")} ({t("form.optional")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="customer_name"
                error={errors.customer_name}
                required={false}
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.mobile")}
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="mobile"
                error={errors.mobile}
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.email")} ({t("form.optional")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="email"
                error={errors.email}
                type="email"
                required={false}
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.bank")}
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="bank_id"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    field={field}
                    options={bankOptions}
                    error={errors.bank_id}
                  />
                )}
                rules={{
                  required: t("form.required"),
                }}
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.paymentPeriod")} ({t("form.month")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <Controller
                name="payment_period"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    field={field}
                    options={paymentPeriodOptions}
                    error={errors.payment_period}
                  />
                )}
                rules={{
                  required: t("form.required"),
                }}
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.monthPayment")} ({t("common.currency")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="month_payment"
                error={errors.month_payment}
                type="number"
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.paidMoney")} ({t("common.currency")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="paid_money"
                error={errors.paid_money}
                type="number"
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.salary")} ({t("common.currency")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="salary"
                error={errors.salary}
                type="number"
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.commitments")} ({t("common.currency")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="commitments"
                error={errors.commitments}
                type="number"
              />
            </div>
          </div>
          <div className="uk-margin-bottom">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.lastPayment")} ({t("common.currency")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="lastpayment"
                error={errors.lastpayment}
                type="number"
              />
            </div>
          </div>
          <div className="uk-margin-bottom uk-width-1-1">
            <label className="uk-form-label uk-text-bold uk-text-primary">
              {t("form.remarks")} ({t("form.optional")})
            </label>
            <div className="uk-form-controls uk-margin-small-top">
              <CustomInput
                control={control}
                name="remarks"
                error={errors.remarks}
                textarea
                required={false}
              />
            </div>
          </div>
          <div className="actions uk-margin-top uk-width-1-1 uk-text-center">
            <CustomButton text={t("financeCalc.calc")} />
          </div>
        </form>
      )}
    </>
  );
}

export default FinanceCalcForm;
