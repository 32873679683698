import { useState } from "react";
import axios from "axios";
import UIkit from "uikit";
import i18next from "i18next";

function useApiResolver() {
  const isEnglish = i18next.language === "en";
  const [data, setData] = useState([]);
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const resolveApi = async ({
    path,
    method = "get",
    body,
    errMeg,
    successMsg,
  }) => {
    setIsLoading(true);
    try {
      const response =
        method == "post"
          ? await axios.post(`${path}`, body).then((res) => res.data)
          : method == "put"
          ? await axios.put(`${path}`, body).then((res) => res.data)
          : await axios.get(`${path}`).then((res) => res.data);

      setData(response);
      setIsLoading(false);
      setIsSuccess(true);

      successMsg &&
        UIkit.notification({
          message: successMsg,
          pos: isEnglish ? "top-right" : "top-left",
          status: "success",
        });
    } catch (err) {
      setError(err);
      errMeg &&
        UIkit.notification({
          message: errMeg,
          pos: isEnglish ? "top-right" : "top-left",
          status: "danger",
        });
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  return {
    data,
    error,
    isLoading,
    resolveApi,
    isSuccess,
    isFailed,
  };
}

export default useApiResolver;
