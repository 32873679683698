import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApiResolver from "../../hooks/useApiResolver";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import IntroSection from "../../components/IntroSection";
import SathaForm from "./SathaForm";

function SathaRequest() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";

  const {
    resolveApi: getBrands,
    isLoading: brandsIsLoading,
    data: brands,
  } = useApiResolver();

  const {
    resolveApi: getMaintenanceTypes,
    isLoading: maintenanceTypesIsLoading,
    data: maintenanceTypes,
  } = useApiResolver();

  const {
    resolveApi: getBranches,
    isLoading: branchesIsLoading,
    data: branches,
  } = useApiResolver();

  useEffect(() => {
    getBrands({ path: "/Brands/WebGetAll" });
    getMaintenanceTypes({ path: "/MaintenanceTypes/GetAll" });
    getBranches({ path: "/Branches/MWebGetAll" });
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  // Brand Options
  const brandOptions = brands.map((brand) => ({
    value: brand.id,
    label: isEnglish ? brand.nameEn : brand.nameAr,
  }));

  // Maintenance Types Options
  const maintenanceTypesOptions = maintenanceTypes.map((type) => ({
    value: type.id,
    label: `${isEnglish ? type.nameEn : type.nameAr} - ${type.price} ${t(
      "common.currency"
    )}`,
  }));

  // Branches Options
  const branchesOptions = branches.map((branch) => ({
    value: branch.id,
    label: isEnglish ? branch.nameEn : branch.nameAr,
  }));

  // Delivery Time Options
  const deliveryTimeOptions = [
    {
      value: 0,
      label: t("form.morning"),
    },
    {
      value: 1,
      label: t("form.afternoon"),
    },
    {
      value: 2,
      label: t("form.evening"),
    },
  ];

  return (
    <>
      {(isLoading ||
        brandsIsLoading ||
        branchesIsLoading ||
        maintenanceTypesIsLoading) && <LoadingOverlay fullScreen transparent />}

      <IntroSection
        headerPartOne={t("satha.header.headerPartOne")}
        headerPartTwo={t("satha.header.headerPartTwo")}
      />

      <section className="uk-container uk-container-small uk-padding-large">
        <SathaForm
          brandOptions={brandOptions}
          branchesOptions={branchesOptions}
          maintenanceTypesOptions={maintenanceTypesOptions}
          deliveryTimeOptions={deliveryTimeOptions}
          setIsLoading={setIsLoading}
        />
        <div className=" uk-width-1-1 uk-text-meta uk-margin-top">
          <span className="uk-text-danger">* </span>
          {t("satha.hint")}
        </div>
      </section>
    </>
  );
}

export default SathaRequest;
