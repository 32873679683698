import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import useApiResolver from "../../hooks/useApiResolver";
import IntroSection from "../../components/IntroSection";

function Branches() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: branches } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/Branches/WebGetAll" });
  }, []);

  return (
    <>
      <IntroSection
        headerPartOne={t("branches.header.headerPartOne")}
        headerPartTwo={t("branches.header.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="branches uk-container uk-container-small uk-padding-large">
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-grid-large uk-grid-match"
            uk-grid=""
          >
            {branches?.map((branch) => (
              <div key={branch.id}>
                <div className="branch uk-border-rounded">
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${branch.location.lat},${branch.location.lng}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="location-icon fi fi-tr-land-layer-location"></i>
                  </a>
                  <h3>{isEnglish ? branch.nameEn : branch.nameAr}</h3>
                  <p>{isEnglish ? branch.addressEn : branch.addressAr}</p>

                  <div>
                    <div>
                      <i className="icon fi fi-tr-phone-call"></i>
                      <p className="uk-display-inline-block">{branch.phone}</p>
                    </div>
                    <div>
                      <i className="icon fi fi-rr-time-quarter-to"></i>
                      <p className="uk-display-inline-block">
                        {t("branches.from")}
                        <span className="uk-padding-small">
                          {branch.start_work}
                        </span>
                        {t("branches.to")}
                        <span className="uk-padding-small">
                          {branch.end_work}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

export default Branches;
