import React from "react";
import AppRoutes from "./routes";
import NavBar from "./layouts/NavBar";
import Footer from "./layouts/Footer";
import FloatingChatBox from "./components/FloatingChatBox";

function App() {
  return (
    <>
      <NavBar />
      <AppRoutes />
      <FloatingChatBox />
      <Footer />
    </>
  );
}

export default App;
