import React from "react";
import mini_logo from "../assets/imgs/mini_logo.png";

function LoadingOverlay({ fullScreen, transparent }) {
  return (
    <div
      className={`loading-overlay${fullScreen ? " fullScreen" : ""}${
        transparent ? " transparent" : ""
      }`}
    >
      <div className="loading-spinner">
        <div className="l-spinner">
          <img src={mini_logo} />
        </div>
      </div>
    </div>
  );
}

export default LoadingOverlay;
