import React from "react";
import bg from "../../assets/imgs/home/contact_bg.png";
import { useTranslation } from "react-i18next";

function ContactSection() {
  const { t } = useTranslation();

  return (
    <section
      className="uk-height-small uk-background-cover"
      data-src={bg}
      uk-img=""
    >
      <div className="uk-container uk-container-small uk-flex uk-flex-center uk-flex-middle uk-height-1-1 uk-padding-remove-horizontal@l">
        <div
          className="uk-width-1-1 uk-grid-small uk-child-width-1-2"
          uk-grid=""
        >
          <div>{t("home.contact.header")}</div>
          <div className="uk-text-left">
            <a
              href="https://api.whatsapp.com/?phone=+966590993939"
              target="_blank"
              rel="noreferrer"
              className="uk-button uk-button-secondary uk-border-pill"
            >
              <span className="uk-margin-small-left" uk-icon="whatsapp"></span>

              {t("common.contact")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
