import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { BrowserRouter } from "react-router-dom";
import "./locales/i18n";
import i18next from "./locales/i18n";
import "uikit/dist/css/uikit-rtl.min.css";
import "@flaticon/flaticon-uicons/css/all/all.css";
import "./assets/css/style.css";
import "./assets/css/LTR_Style.css";
import "./config/AxiosConfig";
import ScrollTop from "./helpers/ScrollTop";

UIkit.use(Icons);

document.body.dir = i18next.dir();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollTop />
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
