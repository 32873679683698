import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTranslation } from "react-i18next";
import mini_logo from "../../assets/imgs/mini_logo.png";

function FloatingChatBox() {
  const { t } = useTranslation();

  return (
    <FloatingWhatsApp
      phoneNumber="+966590993939"
      accountName={t("chatBox.accountName")}
      placeholder={t("chatBox.inputPlaceholder")}
      statusMessage={t("chatBox.statusMessage")}
      chatMessage={t("chatBox.chatMessage")}
      avatar={mini_logo}
      allowEsc
      allowClickAway
      darkMode
    />
  );
}

export default FloatingChatBox;
