import React from "react";
import miniLogo from "../assets/imgs/mini_logo.png";

function CustomHeader({
  label,
  headerPartOne,
  headerPartTwo,
  centerText,
  padding,
}) {
  return (
    <div
      className={`custom-header${
        padding === "small"
          ? " uk-padding-small"
          : padding === "none"
          ? ""
          : " uk-padding"
      } uk-padding-remove-horizontal${centerText ? " uk-text-center" : ""}`}
    >
      {label && (
        <p className="uk-text-white">
          <img src={miniLogo} alt="image" width="30" />
          {label}
        </p>
      )}
      {headerPartOne && (
        <h1 className="uk-margin-small-top uk-text-white uk-text-bold">
          {headerPartOne}
          {headerPartTwo && (
            <span className="uk-text-primary"> {headerPartTwo}</span>
          )}
        </h1>
      )}
    </div>
  );
}

export default CustomHeader;
