import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import { useTranslation } from "react-i18next";

function CustomDatePicker({
  name,
  control,
  error,
  onChange,
  format = "d/M/yyyy",
  allowClear,
  required = true,
}) {
  const { t } = useTranslation();
  const handleTimeZone = (date) => {
    const formattedDate = new Date(
      new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000
    ).toJSON();

    return formattedDate;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            value={handleTimeZone(field.value)}
            format={format}
            locale="ar"
            clearIcon={allowClear}
            onChange={(date) => {
              field.onChange(handleTimeZone(date));
              onChange && onChange(handleTimeZone(date));
            }}
          />
        )}
        rules={{
          required: required && t("form.required"),
        }}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span uk-icon="warning"></span>
          <span className="uk-margin-small-right uk-margin-small-left">
            {error.message}
          </span>
        </small>
      )}
    </>
  );
}

export default CustomDatePicker;
