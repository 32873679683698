import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

function CustomInput({
  name,
  control,
  type,
  error,
  placeholder,
  onChange,
  className,
  textarea = false,
  required = true,
  percentage = false,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          textarea ? (
            <textarea
              placeholder={placeholder ?? ""}
              className={className ?? "uk-textarea uk-border-rounded"}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange && onChange(e.target.value);
              }}
              aria-invalid={error ? "true" : "false"}
            ></textarea>
          ) : (
            <input
              type={type ?? "text"}
              placeholder={placeholder ?? ""}
              className={className ?? "uk-input uk-border-rounded"}
              value={field.value}
              onChange={(e) => {
                field.onChange(
                  type === "number" && e.target.value != ""
                    ? parseFloat(e.target.value)
                    : e.target.value
                );
                onChange &&
                  onChange(
                    type === "number"
                      ? parseInt(e.target.value)
                      : e.target.value
                  );
              }}
              aria-invalid={error ? "true" : "false"}
            />
          )
        }
        rules={{
          required: required && t("form.required"),
          pattern: type === "number" && {
            value: percentage
              ? /^((100((\.|,)[0-9]{1,2})?)|([0-9]{1,2}((\.|,)[0-9]{0,2})?))$/
              : /^[0-9]{11}$/,
            message: t("form.invalidNumber"),
          },
          min: percentage
            ? {
                value: 0,
                message: t("form.invalidNumber"),
              }
            : null,
          max: percentage
            ? {
                value: 100,
                message: t("form.invalidNumber"),
              }
            : null,
        }}
      />
      {error && (
        <small className="uk-text-danger uk-display-block uk-margin-small-top">
          <span uk-icon="warning"></span>
          <span className="uk-margin-small-right uk-margin-small-left">
            {error.message}
          </span>
        </small>
      )}
    </>
  );
}

export default CustomInput;
