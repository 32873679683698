import React from "react";
import CustomHeader from "../../components/CustomHeader";
import { useTranslation } from "react-i18next";
import about_pic from "../../assets/imgs/home/about_pic.png";
import CustomButton from "../../components/CustomButton";

function AboutSection() {
  const { t } = useTranslation();

  return (
    <section className="uk-container uk-container-small uk-padding-large uk-padding-remove-horizontal@l">
      <CustomHeader
        label={t("common.headerLabel")}
        headerPartOne={t("home.about.header.headerPartOne")}
        headerPartTwo={t("home.about.header.headerPartTwo")}
        centerText
      />

      <div className="uk-grid-medium uk-child-width-1-2@m" uk-grid="">
        <img src={about_pic} alt="image" />

        <div>
          <p>{t("home.about.description")}</p>

          <div
            className="uk-grid-small uk-chil-width-1-2 uk-margin-bottom"
            uk-grid=""
          >
            <p className="uk-flex uk-flex-middle">
              <i className="fi fi-tr-employees uk-text-primary icon-3x"></i>

              <span className="uk-padding-small uk-padding-remove-vertical">
                {t("home.about.labelOne")}
              </span>
            </p>
            <p className="uk-flex uk-flex-middle">
              <i className="fi fi-tr-onboarding uk-text-primary icon-3x"></i>

              <span className="uk-padding-small uk-padding-remove-vertical">
                {t("home.about.labelTwo")}
              </span>
            </p>
          </div>

          <CustomButton text={t("common.more")} to="/About" />
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
