import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import useApiResolver from "../../hooks/useApiResolver";
import { base_url } from "../../helpers/Constants";
import { NavLink } from "react-router-dom";
import IntroSection from "../../components/IntroSection";

function News() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: news } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/News/GetAll" });
  }, []);

  return (
    <>
      <IntroSection
        headerPartOne={t("news.header.headerPartOne")}
        headerPartTwo={t("news.header.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="uk-container uk-container-small uk-padding-large">
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small"
            uk-grid=""
          >
            {news.map((item) => (
              <div key={item.id} className="news-card uk-position-relative">
                <div
                  className="cover uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover"
                  data-src={`${base_url}/${item.image}`}
                  uk-img=""
                ></div>
                <div className="details">
                  <strong>{isEnglish ? item.nameEn : item.nameAr}</strong>
                  <NavLink to={`/News/${item.id}`} className="more">
                    {t("home.news.viewMore")}
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

export default News;
