import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useApiResolver from "../../hooks/useApiResolver";
import CustomButton from "../../components/CustomButton";

function FinanceCalcResult({
  financeCalcResult,
  setFinanceCalcResult,
  setIsLoading,
}) {
  const { t } = useTranslation();
  const { resolveApi, isLoading, isSuccess } = useApiResolver();

  // Handle show loader
  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  // Handle reset request form
  useEffect(() => {
    isSuccess && setFinanceCalcResult(null);
  }, [isSuccess]);

  // Handle send request
  const handleApproveRequest = async () => {
    const id = financeCalcResult.insertedId;

    await resolveApi({
      path: `/car_finance_orders/ChangeStatus?id=${id}`,
      method: "put",
      successMsg: t("common.requestDone"),
      errMeg: t("common.requestFailed"),
    });
  };

  // Handle cancel request
  const handleCancelRequest = () => {
    setFinanceCalcResult(null);
  };

  return (
    <div>
      <div>
        <p>
          <strong>{t("form.bank")}: </strong>
          {financeCalcResult?.bank}
        </p>
        <p>
          <strong>{t("form.paymentPeriod")}: </strong>
          {financeCalcResult?.payment_period} {t("form.month")}
        </p>
        <p>
          <strong>{t("form.lastPayment")}: </strong>
          {financeCalcResult?.lastPayment} {t("common.currency")}
        </p>
        <p>
          <strong>{t("form.commitments")}: </strong>
          {financeCalcResult?.commitments} {t("common.currency")}
        </p>
        <p>
          <strong>{t("form.paidMoney")}: </strong>
          {financeCalcResult?.paid_money} {t("common.currency")}
        </p>
        <p className="uk-margin-remove uk-padding-small uk-border-rounded bg-primary uk-display-inline-block">
          <strong>
            {t("form.monthPayment")} ({t("common.almost")}):{" "}
          </strong>
          {financeCalcResult?.month_payment} {t("common.currency")}
        </p>
      </div>

      <div className=" uk-width-1-1 uk-text-meta uk-margin-top">
        <span className="uk-text-danger">* </span>
        {t("financeCalc.hintOne")}
      </div>
      <div className=" uk-width-1-1 uk-text-meta uk-margin-top">
        <span className="uk-text-danger">* </span>
        {t("financeCalc.hintTwo")}
      </div>

      <div className="actions uk-margin-top uk-width-1-1 uk-text-center">
        <CustomButton
          text={t("financeCalc.approve")}
          onClick={handleApproveRequest}
          className="uk-margin-small-left uk-margin-small-right"
        />

        <CustomButton
          text={t("financeCalc.cancel")}
          onClick={handleCancelRequest}
          withArrow={false}
          bg="red"
        />
      </div>
    </div>
  );
}

export default FinanceCalcResult;
