import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import { base_url } from "../../helpers/Constants";
import useApiResolver from "../../hooks/useApiResolver";

function Car() {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: carInfo = {} } = useApiResolver();
  const carColors = carInfo?.colors ?? [];
  const carOptions = carInfo?.category_Option ?? [];
  const carImages = carInfo?.images ?? [];

  useEffect(() => {
    resolveApi({ path: `/Cars/${id}` });
  }, [id]);

  return (
    <>
      {isLoading && <LoadingOverlay fullScreen />}

      <section
        className="car-intro uk-height-large uk-background-cover uk-position-relative"
        data-src={`${base_url}/${carInfo?.imageProfile}`}
        uk-img=""
      >
        <div className="uk-position-bottom">
          <div className="uk-container uk-container-small uk-padding uk-padding-remove-bottom">
            <div className="uk-grid-small" uk-grid="">
              <div className="car-title uk-width-1-1 uk-width-1-3@m">
                <h3>{isEnglish ? carInfo.nameEn : carInfo.nameAr}</h3>
                <p>
                  {isEnglish ? carInfo.descriptionEn : carInfo.descriptionAr}
                </p>
              </div>
              <div
                className="tabs uk-width-1-1 uk-width-2-3@m uk-flex uk-flex-bottom uk-margin-small-bottom"
                uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium; toggle: > *; connect: .car-info; swiping: false"
              >
                <button className="uk-button" type="button">
                  {t("car.titles.overview")}
                </button>
                <button className="uk-button" type="button">
                  {t("car.titles.gallery")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-container uk-container-small uk-padding">
        <div className="car-info uk-switcher">
          <div>
            <div className="uk-grid-small uk-child-width-1-2@s" uk-grid="">
              <div>
                <h5 className="uk-text-primary uk-margin-bottom">
                  {t("car.titles.overview")}
                </h5>
                <p className="uk-margin-remove">
                  {isEnglish ? carInfo.overviewEn : carInfo.overviewAr}
                </p>
              </div>
              <div>
                <h5 className="uk-text-primary uk-margin-bottom">
                  {t("car.titles.colors")}
                </h5>
                <div className="car-colors uk-flex">
                  {carColors.map((color) => (
                    <span
                      key={color.code}
                      className="uk-badge uk-border-pill"
                      style={{ backgroundColor: color.code }}
                    ></span>
                  ))}
                </div>
              </div>
            </div>

            <h3 className="uk-text-center uk-text-white uk-margin-large-top uk-margin-medium-bottom">
              {t("car.titles.carOptions")}
            </h3>
            <div
              className="uk-position-relative uk-visible-toggle uk-light uk-text-center navigation-bottom"
              tabIndex="-1"
              uk-slider="sets: true; finite: true"
            >
              <div
                className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@m uk-grid uk-grid-match"
                uk-switcher="connect: .car-options; swiping: false"
              >
                {carOptions.map((option) => (
                  <div key={option.id}>
                    <div className="uk-panel uk-border-rounded">
                      <h5>{isEnglish ? option.nameEn : option.nameAr}</h5>
                      <p>
                        {option.price} {t("common.currency")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="uk-display-block uk-margin-top uk-position-relative uk-flex uk-flex-center uk-flex-middle">
                <button
                  className="uk-link-reset"
                  uk-slidenav-previous=""
                  uk-slider-item="previous"
                ></button>
                <ul className="uk-slider-nav uk-dotnav uk-flex-inline uk-margin-right uk-margin-left"></ul>
                <button
                  className="k-link-reset"
                  uk-slidenav-next=""
                  uk-slider-item="next"
                ></button>
              </div>
            </div>

            <div className="uk-switcher uk-margin-medium-top car-options">
              {carOptions.map((option) => (
                <div key={option.id}>
                  <div
                    className="option-block uk-grid-small uk-child-width-1-2@s"
                    uk-grid=""
                  >
                    {option.engine_capacity !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.engine_capacity")}:</span>
                          {option.engine_capacity}
                        </p>
                      </div>
                    )}
                    {option.engine_power !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.engine_power")}:</span>
                          {option.engine_power}
                        </p>
                      </div>
                    )}
                    <div>
                      <p>
                        <span>{t("car.labels.traction_type")}:</span>
                        {option.traction_type == 0
                          ? t("car.tractionType.automatic")
                          : t("car.tractionType.manual")}
                      </p>
                    </div>
                    {option.max_speed !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.max_speed")}:</span>
                          {option.max_speed}
                        </p>
                      </div>
                    )}
                    {option.cylinder !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.cylinder")}:</span>
                          {option.cylinder}
                        </p>
                      </div>
                    )}
                    {option.fuel_tank_capacity !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.fuel_tank_capacity")}:</span>
                          {option.fuel_tank_capacity}
                        </p>
                      </div>
                    )}
                    {option.fuel_economy !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.fuel_economy")}:</span>
                          {option.fuel_economy}
                        </p>
                      </div>
                    )}
                    <div>
                      <p>
                        <span>{t("car.labels.fuel_type")}:</span>
                        {option.fuel_type == 0
                          ? t("car.fuelType.0")
                          : option.fuel_type == 1
                          ? t("car.fuelType.1")
                          : option.fuel_type == 2
                          ? t("car.fuelType.2")
                          : t("car.fuelType.3")}
                      </p>
                    </div>
                    {option.torque !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.torque")}:</span>
                          {option.torque}
                        </p>
                      </div>
                    )}
                    {option.n_of_seats !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.n_of_seats")}:</span>
                          {option.n_of_seats}
                        </p>
                      </div>
                    )}
                    {option.acceleration !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.acceleration")}:</span>
                          {option.acceleration}
                        </p>
                      </div>
                    )}
                    {option.length !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.length")}:</span>
                          {option.length}
                        </p>
                      </div>
                    )}
                    {option.width !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.width")}:</span>
                          {option.width}
                        </p>
                      </div>
                    )}
                    {option.height !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.height")}:</span>
                          {option.height}
                        </p>
                      </div>
                    )}
                    {option.wheelbase !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.wheelbase")}:</span>
                          {option.wheelbase}
                        </p>
                      </div>
                    )}
                    {option.car_trunk !== null && (
                      <div>
                        <p>
                          <span>{t("car.labels.car_trunk")}:</span>
                          {option.car_trunk}
                        </p>
                      </div>
                    )}
                  </div>

                  <h5 className="uk-text-primary uk-margin-medium-top uk-text-bold">
                    {t("car.luxuries")}
                  </h5>

                  <div
                    className="option-block uk-grid-small uk-child-width-1-2@s"
                    uk-grid=""
                  >
                    {option.cars_Luxury_Technology?.map((luxury) => (
                      <div key={luxury.id}>
                        <p>
                          {isEnglish
                            ? luxury.luxury_technologyEN
                            : luxury.luxury_technologyAR}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div className="actions uk-margin-medium-top">
                    {option.pdf_file !== null && (
                      <a
                        className="uk-link-reset uk-display-inline-block uk-padding-small"
                        href={`${base_url}/${option.pdf_file}`}
                        download={
                          isEnglish
                            ? `${carInfo.nameEn} - ${option.nameEn}`
                            : `${carInfo.nameAr} - ${option.nameAr}`
                        }
                      >
                        <i className="icon fi fi-rr-file-download uk-text-red"></i>
                        {t("car.pdfDownload")}
                      </a>
                    )}
                    <NavLink
                      to={`/FinanceCalc`}
                      state={{
                        id: carInfo.id,
                        price: option.price,
                        image: carInfo?.imageProfile,
                        nameAr: carInfo.nameAr,
                        nameEn: carInfo.nameEn,
                      }}
                      className="uk-button uk-button-primary uk-border-rounded"
                    >
                      <i className="icon fi fi-tr-calculator-money"></i>
                      {t("car.moneyCalc")}
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div
              className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@m"
              uk-grid=""
              uk-lightbox=""
            >
              {carImages.map((image) => (
                <div className="uk-height-small" key={image.file_name}>
                  <div className="uk-position-relative uk-width-1-1 uk-height-1-1">
                    <a
                      href={`${base_url}/${image.file_name}`}
                      className="cursor-zoom-in"
                    >
                      <img
                        className="uk-border-rounded uk-width-1-1 uk-height-1-1 uk-object-cover"
                        src={`${base_url}/${image.file_name}`}
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Car;
