import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import CustomDatePicker from "../../components/CustomDatePicker";
import i18next from "i18next";
import useApiResolver from "../../hooks/useApiResolver";

function MaintenanceForm({
  brandOptions,
  maintenanceTypesOptions,
  branchesOptions,
  deliveryTimeOptions,
  setIsLoading,
}) {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const {
    resolveApi: getBrandTypes,
    data: brandTypes,
    isLoading: brandTypesIsLoading,
  } = useApiResolver();
  const { resolveApi, isLoading } = useApiResolver();

  const formValues = {
    customer_name: "",
    mobile: "",
    email: "",
    brand_Id: "",
    brand_typesId: "",
    maintenanceTypeId: "",
    car_manufactureYear: "",
    car_PlateNumber: "",
    branchId: "",
    date: "",
    deliveryTime: "",
    remarks: "",
  };

  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: formValues,
  });

  // Brand Types Options
  const brandTypesOptions = brandTypes.map((brandType) => ({
    value: brandType.id,
    label: isEnglish ? brandType.nameEn : brandType.nameAr,
  }));

  const handleBrandChange = async (id) => {
    getBrandTypes({
      path: `/brand_types/WebGetAllByBrands/${id}`,
      errMeg: t("common.requestFailed"),
    });
  };

  // Handle send request
  const handleMaintenanceRequest = async (data) => {
    resolveApi({
      path: "/customer_maintenance_orders/AddOne",
      method: "post",
      body: data,
      successMsg: t("common.requestDone"),
      errMeg: t("common.requestFailed"),
    }).then(() => reset(formValues));
  };

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(brandTypesIsLoading);
  }, [brandTypesIsLoading]);

  return (
    <form
      onSubmit={handleSubmit(handleMaintenanceRequest)}
      className="uk-child-width-1-2@s uk-grid-small"
      uk-grid=""
    >
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.name")} ({t("form.optional")})
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="customer_name"
            error={errors.customer_name}
            required={false}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.mobile")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput control={control} name="mobile" error={errors.mobile} />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.email")} ({t("form.optional")})
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="email"
            error={errors.email}
            type="email"
            required={false}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.brand")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="brand_Id"
            control={control}
            render={({ field }) => (
              <CustomSelect
                field={field}
                options={brandOptions}
                onChange={(id) => handleBrandChange(id)}
                error={errors.brand_Id}
              />
            )}
            rules={{
              required: t("form.required"),
            }}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.brandType")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="brand_typesId"
            control={control}
            render={({ field }) => (
              <CustomSelect
                field={field}
                options={brandTypesOptions}
                error={errors.brand_typesId}
              />
            )}
            rules={{
              required: t("form.required"),
            }}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.maintenanceType")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="maintenanceTypeId"
            control={control}
            render={({ field }) => (
              <CustomSelect
                field={field}
                options={maintenanceTypesOptions}
                error={errors.maintenanceTypeId}
              />
            )}
            rules={{
              required: t("form.required"),
            }}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.manufactureYear")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="car_manufactureYear"
            error={errors.car_manufactureYear}
            type="number"
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.plateNumber")} ({t("form.optional")})
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="car_PlateNumber"
            error={errors.car_PlateNumber}
            required={false}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.maintenanceBranch")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="branchId"
            control={control}
            render={({ field }) => (
              <CustomSelect
                field={field}
                options={branchesOptions}
                error={errors.branchId}
              />
            )}
            rules={{
              required: t("form.required"),
            }}
          />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.maintenanceDay")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomDatePicker control={control} name="date" error={errors.date} />
        </div>
      </div>
      <div className="uk-margin-bottom">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.maintenanceTime")}
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <Controller
            name="deliveryTime"
            control={control}
            render={({ field }) => (
              <CustomSelect
                field={field}
                options={deliveryTimeOptions}
                error={errors.deliveryTime}
              />
            )}
            rules={{
              required: t("form.required"),
            }}
          />
        </div>
      </div>
      <div className="uk-margin-bottom uk-width-1-1">
        <label className="uk-form-label uk-text-bold uk-text-primary">
          {t("form.remarks")} ({t("form.optional")})
        </label>
        <div className="uk-form-controls uk-margin-small-top">
          <CustomInput
            control={control}
            name="remarks"
            error={errors.remarks}
            textarea
            required={false}
          />
        </div>
      </div>
      <div className="actions uk-margin-top uk-width-1-1 uk-text-center">
        <button className="uk-button uk-button-primary uk-border-pill">
          {t("form.sendRequest")}
        </button>
      </div>
    </form>
  );
}

export default MaintenanceForm;
