import React from "react";
import bg from "../../assets/imgs/home/offers_bg.png";
import CustomButton from "../../components/CustomButton";
import { useTranslation } from "react-i18next";
import CustomHeader from "../../components/CustomHeader";

function OffersSection() {
  const { t } = useTranslation();

  return (
    <section
      className="uk-height-large uk-background-cover uk-margin-large-top"
      data-src={bg}
      uk-img=""
    >
      <div className="uk-container uk-container-small uk-flex uk-flex-left uk-flex-middle uk-height-1-1 uk-padding-remove-horizontal@l">
        <div className="uk-width-2-3@s uk-width-1-2@m uk-padding-large bg-secondary uk-text-center uk-border-rounded">
          <CustomHeader
            label={t("home.offers.header.label")}
            padding={"none"}
          />
          <h1 className="uk-text-white uk-margin-medium">
            {t("home.offers.description.info")}
            <span className="uk-text-primary">
              {t("home.offers.description.percentage")}
            </span>
          </h1>
          <CustomButton text={t("common.more")} to="/CarOffers" />
        </div>
      </div>
    </section>
  );
}

export default OffersSection;
