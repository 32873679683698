import React from "react";
import aboutBg from "../../assets/imgs/about.png";
import { useTranslation } from "react-i18next";
import CustomHeader from "../../components/CustomHeader";
import NewsSection from "../Home/NewsSection";
import IntroSection from "../../components/IntroSection";

function About() {
  const { t } = useTranslation();

  return (
    <>
      <IntroSection
        headerPartOne={t("about.header.headerPartOne")}
        headerPartTwo={t("about.header.headerPartTwo")}
      />

      <section className="uk-container uk-container-small uk-padding-large">
        <div className="uk-text-white uk-text-center uk-margin-medium">
          {t("about.intro")}
        </div>
      </section>

      <section className="uk-margin-medium">
        <div
          className="uk-width-1-1 uk-grid-small uk-grid-match uk-margin-remove"
          uk-grid=""
        >
          <div className="uk-width-2-5 uk-visible@m uk-padding-remove">
            <div
              className="uk-background-cover uk-margin-medium"
              data-src={aboutBg}
              uk-img=""
            ></div>
          </div>
          <div className="uk-width-1-1 uk-width-3-5@m bg-primary uk-padding uk-text-black">
            <h1 className="uk-text-bold uk-text-black">
              {t("about.advantages.title")}
            </h1>
            <p className="uk-margin-remove uk-text-small">
              {t("about.advantages.description")}
            </p>
            <ul className="uk-list uk-margin">
              <li className="uk-flex uk-flex-middle">
                <i className="fi fi-sr-shield-check icon-2x"></i>
                <span className="uk-padding-small uk-text-bold uk-padding-remove-vertical">
                  {t("about.advantages.label1")}
                </span>
              </li>
              <li className="uk-flex uk-flex-middle">
                <i className="fi fi-sr-shield-check icon-2x"></i>
                <span className="uk-padding-small uk-text-bold uk-padding-remove-vertical">
                  {t("about.advantages.label2")}
                </span>
              </li>
              <li className="uk-flex uk-flex-middle">
                <i className="fi fi-sr-shield-check icon-2x"></i>
                <span className="uk-padding-small uk-text-bold uk-padding-remove-vertical">
                  {t("about.advantages.label3")}
                </span>
              </li>
              <li className="uk-flex uk-flex-middle">
                <i className="fi fi-sr-shield-check icon-2x"></i>
                <span className="uk-padding-small uk-text-bold uk-padding-remove-vertical">
                  {t("about.advantages.label4")}
                </span>
              </li>
              <li className="uk-flex uk-flex-middle">
                <i className="fi fi-sr-shield-check icon-2x"></i>
                <span className="uk-padding-small uk-text-bold uk-padding-remove-vertical">
                  {t("about.advantages.label5")}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="uk-container uk-container-small uk-padding">
        <div className="uk-child-width-1-3@s uk-grid-medium" uk-grid="">
          <div>
            <div className="uk-border-rounded bg-gradient uk-border-rounded uk-text-center uk-padding">
              <h1 className="uk-text-white uk-text-bold">500+</h1>
              <p className="uk-margin-remove">{t("about.clients")}</p>
            </div>
          </div>
          <div>
            <div className="uk-border-rounded bg-gradient uk-border-rounded uk-text-center uk-padding">
              <h1 className="uk-text-white uk-text-bold">7+</h1>
              <p className="uk-margin-remove">{t("about.years")}</p>
            </div>
          </div>
          <div>
            <div className="uk-border-rounded bg-gradient uk-border-rounded uk-text-center uk-padding">
              <h1 className="uk-text-white uk-text-bold">250+</h1>
              <p className="uk-margin-remove">{t("about.maintenance")}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-container uk-container-small uk-padding">
        <CustomHeader
          label={t("common.headerLabel")}
          headerPartOne={t("about.video.partOne")}
          headerPartTwo={t("about.video.partTwo")}
          centerText
        />
        <div>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/mfO9lodg28M?si=4gSv9MNhEXVe1FVs"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="uk-border-rounded"
          ></iframe>
        </div>
      </section>

      <section className="uk-container uk-container-small uk-padding uk-padding-remove-bottom">
        <div
          className="uk-child-width-1-2@s uk-grid-medium uk-grid-match"
          uk-grid=""
        >
          <div>
            <div className="uk-border-rounded bg-with-opacity uk-border-rounded uk-padding">
              <h3 className="uk-text-white uk-text-bold">
                {t("about.goals.title")}
              </h3>
              <p className="uk-margin-remove">{t("about.goals.info")}</p>
            </div>
          </div>
          <div>
            <div className="uk-border-rounded bg-with-opacity uk-border-rounded uk-padding">
              <h3 className="uk-text-white uk-text-bold">
                {t("about.vision.title")}
              </h3>
              <p className="uk-margin-remove">{t("about.vision.info")}</p>
            </div>
          </div>
        </div>
      </section>

      <NewsSection />
    </>
  );
}

export default About;
