import React from "react";
import bg from "../assets/imgs/intro_bg.png";
import CustomHeader from "./CustomHeader";

function IntroSection({ headerPartOne, headerPartTwo }) {
  return (
    <section
      className="intro uk-height-medium uk-background-cover uk-position-relative"
      data-src={bg}
      uk-img=""
    >
      <div className="overlay uk-flex uk-flex-center uk-flex-bottom">
        <div className="uk-container uk-container-small">
          <div>
            <CustomHeader
              headerPartOne={headerPartOne}
              headerPartTwo={headerPartTwo}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
