import React from "react";
import IntroSection from "./IntroSection";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";
import ServicesSection from "./ServicesSection";
import OffersSection from "./OffersSection";
import OptionsSection from "./OptionsSection";
import NewsSection from "./NewsSection";

function Home() {
  return (
    <>
      <IntroSection />
      <AboutSection />
      <ContactSection />
      <ServicesSection />
      <OffersSection />
      <OptionsSection />
      <NewsSection />
    </>
  );
}

export default Home;
