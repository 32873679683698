import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import { base_url } from "../../helpers/Constants";
import useApiResolver from "../../hooks/useApiResolver";
import IntroSection from "../../components/IntroSection";

function WarningSigns() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: warningSigns } = useApiResolver();

  useEffect(() => {
    resolveApi({ path: "/Warn_Signs/GetAll" });
  }, []);

  return (
    <>
      <IntroSection
        headerPartOne={t("warningSigns.header.headerPartOne")}
        headerPartTwo={t("warningSigns.header.headerPartTwo")}
      />

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <section className="warningSigns uk-container uk-container-small uk-padding-large">
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@l uk-grid-small uk-grid-match"
            uk-grid=""
          >
            {warningSigns.map((sign) => (
              <div key={sign.id}>
                <div className="sign uk-border-rounded">
                  <img src={`${base_url}/${sign.image}`} />
                  <h3>{isEnglish ? sign.nameEn : sign.nameAr}</h3>
                  <p>{isEnglish ? sign.description_EN : sign.description_AR}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

export default WarningSigns;
