import React from "react";
import bg from "../../assets/imgs/home/intro_bg.png";
import intro_pic from "../../assets/imgs/home/intro_pic.png";
import CustomHeader from "../../components/CustomHeader";
import { useTranslation } from "react-i18next";

function IntroSection() {
  const { t } = useTranslation();

  return (
    <section
      className="home-intro uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-position-relative"
      data-src={bg}
      uk-img=""
    >
      <div className="uk-container uk-container-small">
        <div
          className="uk-grid-small uk-child-width-1-2@m uk-height-1-1"
          uk-grid=""
        >
          <div className="intro-info">
            <div>
              <CustomHeader
                label={t("home.intro.header.label")}
                headerPartOne={t("home.intro.header.headerPartOne")}
                headerPartTwo={t("home.intro.header.headerPartTwo")}
              />

              <p className="uk-text-white uk-margin-medium-top">
                {t("home.intro.description")}
              </p>

              <a
                href="https://www.youtube.com/@user-vt3ef9xm2b"
                className="more-info"
                target="_blank"
                rel="noreferrer"
              >
                {t("common.more")}
                <span uk-icon="icon: play; ratio: 2"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <img src={intro_pic} alt="image" className="intro-pic uk-visible@m" />
    </section>
  );
}

export default IntroSection;
