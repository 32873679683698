import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useApiResolver from "../../hooks/useApiResolver";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import i18next from "i18next";
import IntroSection from "../../components/IntroSection";
import { useLocation, useNavigate } from "react-router-dom";
import FinanceCalcForm from "./FinanceCalcForm";
import { base_url } from "../../helpers/Constants";

function FinanceCalc() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { state } = useLocation();
  const navigateTo = useNavigate();

  const {
    resolveApi: getBanks,
    isLoading: banksIsLoading,
    data: banks,
  } = useApiResolver();

  // Bank Options
  const bankOptions = banks.map((bank) => ({
    value: bank.id,
    label: isEnglish ? bank.nameEn : bank.nameAr,
  }));

  useEffect(() => {
    getBanks({ path: "/Banks/GetAll" });
  }, []);

  // Show loader before checking state data
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!state) {
      navigateTo("/", { replace: true });
    }
    setIsLoading(false);
  }, [state]);

  return (
    <>
      {(isLoading || banksIsLoading) && (
        <LoadingOverlay fullScreen transparent={banksIsLoading} />
      )}

      <IntroSection
        headerPartOne={t("financeCalc.header.headerPartOne")}
        headerPartTwo={t("financeCalc.header.headerPartTwo")}
      />

      <section className="uk-container uk-container-small uk-padding-large">
        <div className="uk-margin-medium-bottom uk-grid-small" uk-grid="">
          <div>
            <div
              className="uk-height-small uk-width-medium uk-background-cover uk-position-relative uk-border-rounded"
              data-src={`${base_url}/${state?.image}`}
              uk-img=""
            ></div>
          </div>
          <div className="uk-flex uk-flex-middle">
            <div className="uk-padding-small uk-padding-remove-vertical">
              <h2 className="uk-text-white">
                {isEnglish ? state?.nameEn : state?.nameAr}
              </h2>
              <p className="uk-text-primary">
                {state?.price} {t("common.currency")}
              </p>
            </div>
          </div>
        </div>

        <FinanceCalcForm
          car_id={state?.id}
          price={state?.price}
          bankOptions={bankOptions}
          setIsLoading={setIsLoading}
        />
      </section>
    </>
  );
}

export default FinanceCalc;
