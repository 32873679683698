import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import logo from "../../assets/imgs/logo.png";
import CustomButton from "../../components/CustomButton";
import LoadingOverlay from "../../helpers/LoadingOverlay";
import useApiResolver from "../../hooks/useApiResolver";
import UIkit from "uikit";

function NavBar() {
  const { t } = useTranslation();
  const isEnglish = i18next.language === "en";
  const { resolveApi, isLoading, data: brands } = useApiResolver();

  const handleChangeLang = (lang) => {
    i18next.changeLanguage(lang);
    document.body.dir = i18next.dir();
    localStorage.setItem("lang", lang);
  };

  // Hide Off Canvas
  const hideOffCanvas = () => UIkit.offcanvas("#sidebar_menu").hide();

  useEffect(() => {
    resolveApi({ path: "/Brands/WebGetAll" });
  }, []);

  return (
    <>
      <nav className="uk-navbar-container bg-secondary">
        <div className="uk-container uk-container-small">
          <div uk-navbar="mode: click">
            <div className="uk-navbar-left">
              <NavLink to="/" className="uk-navbar-item uk-logo">
                <img src={logo} alt="Aldahyan Logo" />
              </NavLink>
            </div>
            <div className="uk-navbar-right">
              <div className="uk-hidden@l">
                <button
                  className="uk-navbar-toggle uk-navbar-toggle-animate"
                  uk-navbar-toggle-icon=""
                  uk-toggle="target: #sidebar_menu"
                ></button>
              </div>
              <ul className="uk-navbar-nav uk-visible@l">
                <li>
                  <NavLink to="/">{t("navbar.home")}</NavLink>
                </li>
                <li>
                  <a href="#">
                    {t("navbar.aboutUs.title")}
                    <span uk-navbar-parent-icon="true"></span>
                  </a>
                  <div className="uk-navbar-dropdown about-dd">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <NavLink to="/About" uk-toggle="target: .about-dd">
                          {t("navbar.aboutUs.about")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Advices" uk-toggle="target: .about-dd">
                          {t("navbar.aboutUs.advices")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/WarningSigns"
                          uk-toggle="target: .about-dd"
                        >
                          {t("navbar.aboutUs.warningSigns")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Branches" uk-toggle="target: .about-dd">
                          {t("navbar.aboutUs.branches")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/News" uk-toggle="target: .about-dd">
                          {t("navbar.aboutUs.news")}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#">
                    {t("navbar.maintenance.title")}
                    <span uk-navbar-parent-icon="true"></span>
                  </a>
                  <div className="uk-navbar-dropdown maintenance-dd">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <NavLink
                          to="/MaintenanceRequest"
                          uk-toggle="target: .maintenance-dd"
                        >
                          {t("navbar.maintenance.orderMaintenance")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/SathaRequest"
                          uk-toggle="target: .maintenance-dd"
                        >
                          {t("navbar.maintenance.orderSatha")}
                        </NavLink>
                      </li>
                      <li>
                        <a
                          href="https://api.whatsapp.com/?phone=+966590993939"
                          uk-toggle="target: .maintenance-dd"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("navbar.maintenance.orderSpareParts")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#">
                    {t("navbar.cars")}
                    <span uk-navbar-parent-icon="true"></span>
                  </a>
                  <div className="uk-navbar-dropdown brands-dd">
                    {isLoading ? (
                      <LoadingOverlay />
                    ) : (
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        {brands.map((brand) => (
                          <li key={brand.id}>
                            <NavLink
                              to={`/Brand/${brand.id}`}
                              uk-toggle="target: .brands-dd"
                            >
                              {isEnglish ? brand.nameEn : brand.nameAr}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </li>
                <li>
                  <a href="#">
                    {t("navbar.offers.title")}
                    <span uk-navbar-parent-icon="true"></span>
                  </a>
                  <div className="uk-navbar-dropdown offers-dd">
                    <ul className="uk-nav uk-navbar-dropdown-nav">
                      <li>
                        <NavLink to="/CarOffers" uk-toggle="target: .offers-dd">
                          {t("navbar.offers.cars")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/MaintenanceOffers"
                          uk-toggle="target: .offers-dd"
                        >
                          {t("navbar.offers.maintenance")}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="uk-flex uk-flex-center uk-flex-middle uk-height-1-1">
                    <CustomButton
                      text={t("navbar.contact")}
                      to="/Contact"
                      className="contact-link uk-flex uk-flex-center uk-flex-middle"
                    />
                  </div>
                </li>
                <li>
                  {isEnglish ? (
                    <a
                      className="uk-button uk-button-link"
                      onClick={() => handleChangeLang("ar")}
                    >
                      {t("navbar.langAR")}
                    </a>
                  ) : (
                    <a
                      className="uk-button uk-button-link"
                      onClick={() => handleChangeLang("en")}
                    >
                      {t("navbar.langEN")}
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar */}
      <div id="sidebar_menu" uk-offcanvas="overlay: true; mode: push">
        <div className="uk-offcanvas-bar uk-flex uk-flex-column bg-secondary">
          <button
            className="uk-offcanvas-close"
            type="button"
            uk-close=""
          ></button>
          <ul
            className="uk-nav uk-nav-default uk-nav-center uk-margin-auto-vertical"
            uk-nav=""
          >
            <li>
              <NavLink to="/" onClick={hideOffCanvas}>
                {t("navbar.home")}
              </NavLink>
            </li>
            <li className="uk-parent">
              <a href="#">
                {t("navbar.aboutUs.title")}
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/About" onClick={hideOffCanvas}>
                    {t("navbar.aboutUs.about")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/Advices" onClick={hideOffCanvas}>
                    {t("navbar.aboutUs.advices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/WarningSigns" onClick={hideOffCanvas}>
                    {t("navbar.aboutUs.warningSigns")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/Branches" onClick={hideOffCanvas}>
                    {t("navbar.aboutUs.branches")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/News" onClick={hideOffCanvas}>
                    {t("navbar.aboutUs.news")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="uk-parent">
              <a href="#">
                {t("navbar.maintenance.title")}
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/MaintenanceRequest" onClick={hideOffCanvas}>
                    {t("navbar.maintenance.orderMaintenance")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/SathaRequest" onClick={hideOffCanvas}>
                    {t("navbar.maintenance.orderSatha")}
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://api.whatsapp.com/?phone=+966590993939"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar.maintenance.orderSpareParts")}
                  </a>
                </li>
              </ul>
            </li>
            <li className="uk-parent">
              <a href="#">
                {t("navbar.cars")}
                <span uk-nav-parent-icon=""></span>
              </a>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                <ul className="uk-nav-sub">
                  {brands.map((brand) => (
                    <li key={brand.id}>
                      <NavLink
                        to={`/Brand/${brand.id}`}
                        onClick={hideOffCanvas}
                      >
                        {isEnglish ? brand.nameEn : brand.nameAr}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li className="uk-parent">
              <a href="#">
                {t("navbar.offers.title")}
                <span uk-nav-parent-icon=""></span>
              </a>
              <ul className="uk-nav-sub">
                <li>
                  <NavLink to="/CarOffers" onClick={hideOffCanvas}>
                    {t("navbar.offers.cars")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/MaintenanceOffers" onClick={hideOffCanvas}>
                    {t("navbar.offers.maintenance")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/Contact" onClick={hideOffCanvas}>
                {t("navbar.contact")}
              </NavLink>
            </li>
            <li>
              {isEnglish ? (
                <a
                  className="uk-button uk-button-link uk-offcanvas-close"
                  onClick={() => handleChangeLang("ar")}
                >
                  {t("navbar.langAR")}
                </a>
              ) : (
                <a
                  className="uk-button uk-button-link uk-offcanvas-close"
                  onClick={() => handleChangeLang("en")}
                >
                  {t("navbar.langEN")}
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavBar;
