import React from "react";
import CustomHeader from "../../components/CustomHeader";
import { useTranslation } from "react-i18next";
import services_pic from "../../assets/imgs/home/services_pic.png";
import { NavLink } from "react-router-dom";

function ServicesSection() {
  const { t } = useTranslation();

  return (
    <section className="home-services uk-container uk-container-small uk-padding">
      <CustomHeader
        label={t("common.headerLabel")}
        headerPartOne={t("home.services.header.headerPartOne")}
        headerPartTwo={t("home.services.header.headerPartTwo")}
        centerText
      />

      <div className="uk-grid-medium" uk-grid="">
        <div className="uk-width-2-5@m uk-width-1-2@s uk-flex uk-flex-center uk-flex-middle">
          <div className="uk-width-1-1">
            <NavLink to="/Brands" className="uk-link-reset">
              <div className="service uk-border-rounded uk-flex uk-margin-bottom">
                <i className="fi fi-tr-car-rear icon-4x uk-text-primary"></i>
                <div className="info">
                  <h4>{t("home.services.service1.title")}</h4>
                  <p>{t("home.services.service1.info")}</p>
                </div>
              </div>
            </NavLink>

            <NavLink to="/CarOffers" className="uk-link-reset">
              <div className="service uk-border-rounded uk-flex">
                <i className="fi fi-rr-badge-percent icon-4x uk-text-primary"></i>
                <div className="info">
                  <h4>{t("home.services.service2.title")}</h4>
                  <p>{t("home.services.service2.info")}</p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="uk-width-1-5 uk-visible@m">
          <img src={services_pic} alt="image" />
        </div>
        <div className="uk-width-2-5@m uk-width-1-2@s uk-flex uk-flex-center uk-flex-middle">
          <div className="uk-width-1-1">
            <NavLink to="/MaintenanceRequest" className="uk-link-reset">
              <div className="service uk-border-rounded uk-flex uk-margin-bottom">
                <i className="fi fi-tr-car-mechanic icon-4x uk-text-primary"></i>
                <div className="info">
                  <h4>{t("home.services.service3.title")}</h4>
                  <p>{t("home.services.service3.info")}</p>
                </div>
              </div>
            </NavLink>
            <NavLink to="/MaintenanceOffers" className="uk-link-reset">
              <div className="service uk-border-rounded uk-flex">
                <i className="fi fi-rr-settings icon-4x uk-text-primary"></i>
                <div className="info">
                  <h4>{t("home.services.service4.title")}</h4>
                  <p>{t("home.services.service4.info")}</p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
